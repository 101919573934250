import React, { useEffect } from 'react';
import { Button } from '@/components/partials/button/Button';
import { RoleAppPath } from '@/types/rolePaths';
import { useLocation, useNavigate } from 'react-router-dom';
import { setErrorCode, setLoading } from '@/store/slices/appSlice';
import { useTypedDispatch } from '@/store/hooks';
import classNames from 'classnames';
import errorImg from '@/assets/images/errorImage.png';
import styles from './ErrorPage.module.scss';

interface Props {
	httpCode: string;
}

export const ErrorPage: React.FC<Props> = ({ httpCode }) => {
	const { pathname } = useLocation();

	useEffect(() => {
		const nav = document.getElementById('navbar'),
			footer = document.querySelector('footer');
		nav?.classList.add('hidden');
		footer?.classList.add('hidden');
		return () => {
			nav?.classList.remove('hidden');
			footer?.classList.remove('hidden');
		};
	}, []);

	const navigate = useNavigate();
	const dispatch = useTypedDispatch();

	const back = () => {
		const role = pathname.split('/')[1] as RoleAppPath;

		dispatch(setErrorCode(''));
		dispatch(setLoading(false));
		navigate(`/${role}`);
	};

	return (
		<div className={classNames('page-wrapper', styles.container)}>
			<div className={styles.columnText}>
				<h1>Coś poszło nie tak...</h1>
				<h5>{errorMessage(httpCode)}</h5>
				<Button
					themes={['blue']}
					children={<p>Przejdź do głównej strony</p>}
					buttonProps={{
						onClick: back,
					}}
				/>
			</div>
			<div className={styles.columnImage}>
				<img src={errorImg} alt="error code" />
				<h2>{httpCode || '404'}</h2>
			</div>
		</div>
	);
};

function errorMessage(httpCode: string) {
	switch (httpCode) {
		case '404':
			return 'Nie odnaleziono żądanego zasobu';
		case '':
			return 'Taka strona nie istnieje. Wpisz poprawny adres url lub przejdź do głównej strony';
		default:
			return 'Nastąpił nieoczekiwany błąd';
	}
}
